.home-1920-login,
.home-1920-login * {
  box-sizing: border-box;
}
.home-1920-login {
  background: var(--neutralgrey, #eff1f3);
  width: 100%;
  height: 100vh;
  position: relative;
  overflow: hidden;
}
.frame-85 {
  background: rgba(255, 255, 255, 0.16);
  border-radius: 10px;
  padding: 32px 29.5px 32px 29.5px;
  display: flex;
  flex-direction: column;
  gap: 32px;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: calc(50% - 210px);
  top: calc(50% - 356px);
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.3);
}


.images{
  margin-left: 50px;
}
.image-3 {
  flex-shrink: 0;
  width: 120px;
  height: 30px;
  position: relative;
  align-items: center;
  justify-content: center;
  margin-top: 0px;
  margin-left: 90px;
}
@media (max-width: 500px) {
  .image-3 {
    flex-shrink: 0;
    width: 120px;
    height: 30px;
    position: relative;
    align-items: center;
    justify-content: center;
    bottom: 0;
    margin-left: 50px;
  }
}
@media (min-width: 500px) {
  .mobile_view_menu_btn {
    height: 340px;
  }
}

.group-42 {
  flex-shrink: 0;
  width: 361px;
  height: 255px;
  position: static;
}
.asset-component-button {
  border-radius: 4px;
  border-style: solid;
  border-color: var(--darkgrey, #606060);
  border-width: 1px;
  display: flex;
  flex-direction: column;
  gap: 0px;
  align-items: flex-start;
  justify-content: center;
  width: 361px;
  height: 52px;
  position: absolute;
  left: 29.5px;
  top: 253px;
  overflow: hidden;
}
.unstyled-button {
  padding: 8px 22px 8px 22px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
  overflow: hidden;
}
.deep-probe-branding {
  position: absolute;
  left: calc(50% - 70px);
  top: 600px;
  overflow: visible;
}
/* ----------------*/
.paper_style{
    padding: 20;
    height: '70hv';
    width: 280px;
    margin: 20px auto;
}
.active {
  background-color: #D0E2FB ; 
  color: #2F80ED !important; 
  max-width: 100%;
  height: 100%;
  width: 100%;
  border-radius: 5px;
}
.color{
  color: #2F80ED
}
.icon.active {
  color: #2F80ED; /* Set the color to '#606060' when the item is active */
}