.department_main{
    
}
.department_header{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;

}
.department_welcome_text{
font-size: 1rem;
font-style: normal;
color:  #003776; 

}
.department_text{
font-size: 38px;
font-weight: 500;
color:  #003776; 
}
.department_toggler{
    display: flex;
    align-items: flex-start;
   
}
.department_toggler_div{
    padding: 2px;
    background-color: #D6DBE1;
    border-radius: 100px;
}
.department_toggler_button{
    height: 40px;
    width: 150px;
    margin: 2px;
    border-radius: 100px;
    border:gray;
    font-size: 1rem;
}
.department_body{
    margin-top: 24px;
}
.main_department_form{
    display: flex;
    align-items: center;
    justify-content: center;
}
.add_department_form{
    background-color: white;
    padding: 24px;
    padding-top: 0px;
    border-radius: 12px;
    width: 420px;
    height: 280px;
}
.add_department_form_formika{
    display: flex;
    flex-direction: column;
}
.form_label{
    color: #000;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    font: bold;
    display: flex;
    align-items: flex-start;
    margin-bottom: 5px;
    margin-top:10px;
}
.add_department_submit{
    height: 35px;
    margin-top: 15px;
    background-color: #2F80ED;
    border-radius: 4px;
    border:none;
    color:white;

}
 
.dau_body{
    background-color: white;
    border-radius: 5px;
    
}
.dau_body_filter{
    display: flex;
    justify-content: flex-end;
    padding: 5px;
    margin-bottom: 5px;
}
.dau_body_user_table{
    padding: 15px;
}
.dau_div{
    display: flex;
}
.dau_heading{
    font-size: 27px;
}
.dau_body{
    background-color: white;
    border-radius: 5px;
    
}
.dau_body_filter{
    display: flex;
    justify-content: flex-start;
    
    padding: 5px;
    margin-bottom: 5px;

}
.dau_body_user_table{
    padding: 15px;
}