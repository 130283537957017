.body{
    background-color: #828282;
}
.query-hint[_ngcontent-nsm-c105] {
    display: flex;
    justify-content: center;
    text-align: center;
    padding-top: 2rem;
  }
  *, ::after, ::before {
    box-sizing: border-box;
  }
  .mat-body, .mat-body-1, .mat-typography {
    font: 400 14px/20px Roboto,Helvetica Neue,sans-serif;
    letter-spacing: normal;
  }
  

  .rectangle-2,
.rectangle-2 * {
  box-sizing: border-box;
}
.rectangle-2 {
  background: var(--neutralwhite, #fcfbfc);
  border-radius: 5px;
  border-style: solid;
  border-color: var(--gray-5, #e0e0e0);
  border-width: 1px;
  width: 100%;
  height: 100%;
  position: relative;
  box-shadow: 0px 0px 16px 0px rgba(30, 30, 30, 0.16);
  padding: 10px;
}
.biggerTextField {
    width: 95%;
    font-size: 1.5rem;
   
  }
  .biggerField {
    width: 99%;
    /* font-size: 1.5rem; */
   
  }

  .card{
    border-radius: 5px;
    display: grid;
  grid-template-columns: repeat(3, 1fr); /* Three equal-width columns */
  grid-gap: 10px; 
  }
  
  .card-item {
   
    padding: 10px;
    margin-left: 30px;
  }
  .card-item_2{
   
    padding: 10px;
    margin-left: 10px;

  }
  .rectangle-3{
    border-radius: 20px;
  }
  .rectangle-4{
    display: block;
    box-sizing: border-box;
    background: var(--neutralwhite, #fcfbfc);
  border-radius: 5px;
  border-style: solid;
  border-color: var(--gray-5, #e0e0e0);
  border-width: 1px;
  max-width:820px;
  max-height: 100%;
  position: relative;
  box-shadow: 0px 0px 16px 0px rgba(30, 30, 30, 0.16);
  padding: 16px;
  margin: 5px;
  }
  @media (max-width: 500px) {
    .mobile_view_1{
      width: 100%;
      
    }
  }
  @media (min-width:500px) {
    .modal_size{
      width: 425px;
    }
  }
 
  .search_bar{
    display: flex;
    height: '50px';
    padding-top: '0px';
    margin: '24px'
  }